import React from "react";
import "./Contacts.css"

function SocialIcon({img, url})
{
	return (
		<a className="social-icon" target="_blank" rel="noreferrer" href={url}><img src={img} alt="social-icon"/></a>
	)
}

function isShopOpen(openingTimes)
{
	const now = new Date();
	const hm = (now.getHours() < 10 ? "0" : "") + now.getHours() + ":" + now.getMinutes();

	// sunday or monday morning
	if(now.getDay() === 0 || (now.getDay() === 1 && hm < openingTimes[1].open))
		return false;
	else
		return openingTimes.filter(opening => hm > opening.open && hm < opening.close).length === 1;
}

function timeDifference(timeString)
{
	const hours = parseInt(timeString.split(":")[0]);
	const minutes = parseInt(timeString.split(":")[1]);
	const now = new Date();
	let diff = (hours - now.getHours()) * 60 + minutes - now.getMinutes();
	diff = diff + (diff < 0 ? 1440 : 0);

	if(diff > 240)
		return "alle " + timeString;
	else if(diff > 90)
		return "tra " + Math.round(diff/60) + " ore";
	else if(diff > 60)
		return "tra 1 ora";
	else if(diff > 1)
		return "tra " + diff + " minuti";
	else
		return "tra 1 minuto";
}

function shopFutureStatus(openingTimes)
{
	const now = new Date();
	const hm = (now.getHours() < 10 ? "0" : "") + now.getHours() + ":" + now.getMinutes();

	// sunday
	if(now.getDay() === 0)
		return "Apre domani alle " + openingTimes[1].open;

	const action = isShopOpen(openingTimes) ? "Chiude" : "Apre";
	let time;

	if(now.getDay() === 1 && hm < openingTimes[1].open) // monday morning
		time = openingTimes[1].open;
	else if(isShopOpen(openingTimes)) // shop is open
		time = openingTimes.filter(opening => hm > opening.open && hm < opening.close)[0].close;
	else // shop is close
	{
		const times = openingTimes.filter(opening => hm<opening.open); // all next opening times
		time = times.length > 0 ? times[0].open : openingTimes[0].open; // check if it's the last opening time of the day
	}
	return action + " " + timeDifference(time);
}

function TimeTable({openingTimes})
{
	const [currentStatus, setCurrentStatus] = React.useState(() => isShopOpen(openingTimes));
	const [futureStatus, setfutureStatus] = React.useState(() => shopFutureStatus(openingTimes));

	React.useEffect(() => {
		setInterval(() => {
			setCurrentStatus(isShopOpen(openingTimes));
			setfutureStatus(shopFutureStatus(openingTimes));
		}, 30000);
	}, [openingTimes]);

	return (
		<div className="col">
			<h2>Orario di Apertura</h2>
			<p>Da Lunedì a Sabato</p>
			<p>
				<strong>Mattino</strong> {openingTimes[0].open} - {openingTimes[0].close}
				<br/>
				<strong>Pomeriggio</strong> {openingTimes[1].open} - {openingTimes[1].close}
			</p>
			<p>Lunedì mattino chiuso</p>
			<div className="current-status">
				<h3>{currentStatus  ? "Aperto ora" : "Chiuso ora"}</h3>
				<p>{futureStatus}</p>
			</div>
		</div>
	)
}

export default function Contacts()
{
	const [openingTimes, setOpeningTimes] = React.useState([]);

	React.useEffect(() => {
		fetch("https://script.google.com/macros/s/AKfycbznGKAyS4TXnvPphP0Vebx78U9U7fhnawiNfRWnmxDBnbcWgPjIPuAv042JCrnmpBzd/exec")
			.then(response => response.json())
			.then(data => setOpeningTimes(data));
	}, []);

	return (
		<div id="contacts">
			<div className="info">
				<div className="col">
					<h2>Acquamarina s.a.s.</h2>
					<p>Via Vincenzo Rossi 66, Pesaro (PU)</p>
					<p><strong>Telefono</strong> <a className="contact-link" href="tel:+390721414025">0721 414025</a><br/><strong>Email</strong> <a className="contact-link" href="mailto:info@acquamarinabiancheria.it">info@acquamarinabiancheria.it</a></p>
					<div className="social-icons">
						<SocialIcon img="/social-icons/facebook.webp" url="https://www.facebook.com/acquamarinapesaro"/>
						<SocialIcon img="/social-icons/maps.webp" url="https://g.page/acquamarinapesaro/"/>
						<SocialIcon img="/social-icons/whatsapp.webp" url="https://wa.me/390721414025/"/>
					</div>
				</div>
				{openingTimes.length !== 0 && <TimeTable openingTimes={openingTimes}/>}
			</div>
			<iframe title="maps" className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2874.71213979956!2d12.906403415728962!3d43.903228344244454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132d194f8bf435e7%3A0x22bdf4669f05c599!2sAcquamarina%20Intimo%20e%20Biancheria!5e0!3m2!1sit!2sit!4v1659345406734!5m2!1sit!2sit"></iframe>
		</div>
	)
}