import React from "react";
import "./Brands.css"

function BrandLogo({url, img})
{
	return (<a href={url} target="_blank" rel="noreferrer" className="brand-logo"><img alt="brand-logo-img" src={img}/></a>)
}

export default function BrandList()
{
	return (
		<div id="brands">
			<BrandLogo img="/brands/BananaMoon.webp" url="https://bananamoon.com/it/"/>
			<BrandLogo img="/brands/Bottaro.webp" url="https://bottaro.it/"/>
			<BrandLogo img="/brands/Cinelli.webp" url="https://www.cinellipiumini.it/"/>
			<BrandLogo img="/brands/Daunex.webp" url="https://www.daunex.it/"/>
			<BrandLogo img="/brands/Julipet.webp" url="https://julipet.it/"/>
			<BrandLogo img="/brands/Liu-Jo.webp" url="https://www.liujo.com/it/"/>
			<BrandLogo img="/brands/MarinaMilitare.webp" url="https://www.marinamilitare-sportswear.com/it"/>
			<BrandLogo img="/brands/mirabello.webp" url="https://mirabellocarrara.it/"/>
			<BrandLogo img="/brands/Maryplaid.webp" url="https://www.maryplaid.it/"/>
			<BrandLogo img="/brands/Noidinotte.webp" url="https://www.noidinotte.it/it"/>
			<BrandLogo img="/brands/OroBlu.webp" url="https://www.oroblu.com"/>
			<BrandLogo img="/brands/Ritratti.webp" url="https://ritratti.com/"/>
		</div>
	)
}
